import last from 'lodash/last'
import routes from '../../routes'
import PageComponent from '../Components/Page'
import getPageData from '../server/getPageData'

export default function Page (props) {
  return <PageComponent {...props} />
}

export async function getStaticProps ({
  preview = false,
  previewData,
  params
}) {
  // const { countryCode } = params || {}
  // const route = routes.routes[countryCode]

  const pageData = await getPageData(
    routes.defaultRoute.countryCode,
    '404',
    {
      active: preview,
      token: previewData?.token
    })

  return {
    props: {
      data: pageData,
      preview
    },
    revalidate: 60 * 60 * 24 // 1 day revalidation
  }
}
